/* eslint-disable no-unused-vars */
<template>
<div>
  <div class="text-center" v-if="loading" style="display: flex; width: 100%; height: 100%; position:fixed; top:0; z-index:9999; cursor: pointer; background-color: rgba(0,0,0,0.7);">
    <div class="spinner-border text-light" style="width: 6rem; height: 6rem; margin: auto;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div class="wrapper">
    <Header></Header>
    <SidebarMenu></SidebarMenu>

    <div class="content-wrapper kanban" ref="wrapper">   
        <section class="content-header">
            <b-container fluid>
                <div class="row">
                    <div class="col-md-6" style="display: inline;">
                        <h4 style="display: inline; float:left;">{{ board.name }}</h4>
                        <div style="display: inline;" class="board-header-facepile">
                            <a tabindex="0" class="member js-member ui-draggable ui-draggable-handle" style="z-index: 1; color: #fff; background-color: #000;">
                                <span v-if="board.author != null" class="member-initials" :title="board.author.first_name + board.author.last_name" :aria-label="board.author.first_name + board.author.last_name">{{board.author.first_name.charAt(0).toUpperCase()}}{{board.author.last_name.charAt(0).toUpperCase()}}</span>
                            </a>
                            <a v-for="user in board.users" :key="user.id" tabindex="0" class="member js-member ui-draggable ui-draggable-handle" style="z-index: 1;">
                                <span class="member-initials" :title="user.first_name + user.last_name + '(' + user.username + ')'" :aria-label="user.first_name + user.last_name + '(' + user.username + ')'">{{user.first_name.charAt(0).toUpperCase()}}{{user.last_name.charAt(0).toUpperCase()}}</span>
                            </a>
                        </div>
                    </div>

                    <div class="col-md-6" v-if="board.author != null">
                        <nav class="py-0 bg-transparent navbar navbar-expand navbar-light justify-content-end">
                            <ul class="navbar-nav">  
                                <li class="nav-item d-none d-md-block">
                                    <div class="mr-1 btn-group" role="group">
                                        <button id="btnGroupDrop1" type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Eigenschaften
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">        
                                            <a class="dropdown-item" href="#" @click="showBackgroundModal">Hintergrund</a>
                                            <a class="dropdown-item" href="#" @click="showShareModal" v-if="board.author.id == $auth.user().id">Teilen</a>
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item text-danger" @click="deleteBoard" href="#" v-if="board.author.id == $auth.user().id">Löschen</a>
                                        </div>
                                    </div>
                                </li>

                                
                            </ul>

                        </nav>
                    </div>
                </div>
            </b-container>
        </section>
        <section class="content pb-3">
            <b-container fluid class="h-100">
                <template v-if="board.lists.length > 0">
                    <draggable v-model="board.lists" group="lists" tag="div" style="display: flex;" animation="200" ghostClass="ghost" @change="listMoved" @start="isDragging = true" @end="isDragging = false">
                        <Listing v-for="listing in board.lists" :key="listing.id" :list="listing" :users="users" :tags="tags" v-on:delete-list="deleteListEntry"/>
                    </draggable>
                </template>
                <div class="mr-3 mt-3" style="width:340px;">
                    <button class="btn btn-secondary btn-sm form-control" @click="showForm" v-if="showCreateForm == false">Neue Liste erstellen</button>
                    <div style="background-color: #ebecf0;" class="p-2 rounded" v-if="showCreateForm == true">
                        <input type="text" class="form-control form-control-sm mt-2 rounded" placeholder="Enter list title..." v-model="new_list_name"/>
                        <button type="button" class="btn btn-sm btn-primary rounded mt-2 mr-2" @click="createListing">Liste erstellen</button>
                        <button type="button" class="btn btn-sm btn-danger rounded mt-2" @click="closeForm"><i class="fas fa-times"></i></button>
                    </div>
                </div>

                <b-modal id="backgroundModal" title="Hintergrund ändern" ok-only ok-variant="primary" ok-title="Speichern" size="sm" @ok="handleBackgroundOk">

                    <div class="form-group">
                        <label for="tag" class="control-label">Hintergrund*</label>
                        <ul class="new_board_list">
                            <li v-for="tag in tags" :key="tag.id" class="new_board_item">
                                <button type="button" :class="tag.content" @click="selectTag(tag.id)">
                                    <span class="new_board_item_icon" v-if="tag.id == selectedBackground">
                                        <span class="new_board_item_icon_self" role="img" aria-label="CheckIcon">
                                            <svg width="24" height="24" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.73534 12.3223C6.36105 11.9162 5.72841 11.8904 5.3223 12.2647C4.91619 12.639 4.89039 13.2716 5.26467 13.6777L8.87678 17.597C9.41431 18.1231 10.2145 18.1231 10.7111 17.6264C10.7724 17.5662 10.7724 17.5662 11.0754 17.2683C11.3699 16.9785 11.6981 16.6556 12.0516 16.3075C13.0614 15.313 14.0713 14.3169 15.014 13.3848L15.0543 13.3449C16.7291 11.6887 18.0004 10.4236 18.712 9.70223C19.0998 9.30904 19.0954 8.67589 18.7022 8.28805C18.309 7.90022 17.6759 7.90457 17.2881 8.29777C16.5843 9.01131 15.3169 10.2724 13.648 11.9228L13.6077 11.9626C12.6662 12.8937 11.6572 13.8889 10.6483 14.8825C10.3578 15.1685 10.0845 15.4375 9.83288 15.6851L6.73534 12.3223Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>

                </b-modal>

                <b-modal id="userModal" title="Board teilen" ok-only ok-variant="primary" ok-title="Speichern" size="lg" @ok="handleUser">

                    <div class="form-group row">
                        <label for="" class="control-label col-sm-3 col-form-label col-form-label-sm">Benutzer*</label>
                        <div class="col-sm-9">
                            <Select2 v-model="selectedUsers" :options="users" :settings="{ multiple: true }" class aria-placeholder="Benutzer wählen"/>
                        </div>
                    </div>
                </b-modal>
            </b-container>
        </section>
    </div>

    <Footer></Footer>
  </div>
</div>




</template>

<script>
import Vue from 'vue'
import draggable from 'vuedraggable';
import Listing from './Listing';
import Header from "./../../components/main/Header";
import SidebarMenu from "./../../components/main/SidebarMenu";
import Footer from "./../../components/main/Footer";
import {mapState} from 'vuex';
import Echo from 'laravel-echo'
// eslint-disable-next-line no-unused-vars
import pusher from 'pusher-js'
import VueEcho from 'vue-echo-laravel';
let EchoInstance = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    wsHost: process.env.VUE_APP_PUSHER_HOST,
    wsPort: process.env.VUE_APP_PUSHER_PORT,
    wssPort: process.env.VUE_APP_PUSHER_PORT,
    forceTLS:  process.env.VUE_APP_PUSHER_SCHEME === 'https',
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authEndpoint: process.env.VUE_APP_API_URL + 'broadcasting/auth',
    auth: {
        headers: {
            Authorization: 'Bearer ' + Vue.auth.token(),
            Accept: 'application/json',
        }
    }
});

Vue.use(VueEcho, EchoInstance);


export default {
    components: {
        draggable,
        Listing,
        Header,
        SidebarMenu,
        Footer
    },
  
  computed: {
    ...mapState('loader', ['loading'])
  },

    data(){
        return{
            polling: null,
            board: {
                lists: [],
                cover: {
                    id: null,
                }, 
            }, 
            author: [], 
            users: [],
            isDragging: false,
            showCreateForm: false,
            new_list_name: null,
            tags: [],
            selectedUsers: [],
            selectedBackground: null,
        }
    },

    methods:{
        deleteBoard(){
            this.$swal({
                    title: "Möchtest du das Board wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, LÖSCHEN!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .delete("/boards/" + this.board.id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Board gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                });
                                this.$router.push({name: 'boards.index'});
                            })
                            .catch((error) => {
                                var message = 'Da ist etwas schief gelaufen'; 
                                if('error' in error.response.data)
                                {
                                    message = error.response.data.error; 
                                }
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: message, 
                                });
                            });
                    }
                });
        },


        shareUsers(){
            this.axios
                .put('/boards/' + this.$route.params.id, {
                    'users': this.selectedUsers
                })
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    this.board.users = response.data.data.users;
                    this.$bvModal.hide("userModal");
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        handleUser(bvModalEvt){
            bvModalEvt.preventDefault();
            this.shareUsers();
        },

        showShareModal(){
            this.selectedUsers = [];
            this.board.users.forEach(function(user){
                this.selectedUsers.push(user.id)
            },this)
            this.$bvModal.show("userModal");
        },

        deleteListEntry(){
            console.log('deleted: ');
        },

        editBackground(){
            this.axios
                .put('/boards/' + this.$route.params.id, {
                    'cover_id': this.selectedBackground
                })
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    this.board = response.data.data;
                    // var cls = this.$refs.wrapper.classList.value.split(' ');
                    // this.$delete(cls, cls.length-1)
                    this.$refs.wrapper.classList.value = 'content-wrapper kanban ' + this.board.cover.content
                    this.$bvModal.hide("backgroundModal");
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        handleBackgroundOk(bvModalEvt){
            bvModalEvt.preventDefault();
            this.editBackground();
        },

        selectTag(id){
            this.selectedBackground = id;
        },

        showBackgroundModal(){
            if(this.board.cover_id != null){
                this.selectedBackground = this.board.cover_id;
            }
            else{
                this.selectedBackground = null;
            }
            this.$bvModal.show("backgroundModal");
        },

        closeForm(){
            this.showCreateForm = false;
            this.new_list_name = null;
        },

        showForm(){
                this.showCreateForm = true;
        },

        // log: function(evt) {
        //     // window.console.log(evt);
        //     if("moved" in evt){
        //         //Listing wurde gemoved
        //         this.changeListSort();
        //     }
        // },

        loadTags(){
            this.axios
                .get("/tags", {
                    params: {
                        per_page: 150,
                    }
                })
                .then((response) => {
                    this.tags = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        createListing(){
            this.axios
                .post('/listings', {
                    board_id: this.$route.params.id,
                    name: this.new_list_name,
                })
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Liste erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        });
                    this.showCreateForm = false;
                    this.new_list_name = null;
                    this.loadBoard();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
        
        listMoved(e){
            // console.log(e);
            let item = e.added || e.moved;

            if(!item) return;

            let index = item.newIndex;
            let prevList = this.board.lists[index - 1];
            let nextList = this.board.lists[index + 1];
            let list = this.board.lists[index];
            
            let position = list.position;

            // console.log(prevList);
            // console.log(nextList);
            
            if (prevList && nextList) {
                position = (prevList.position + nextList.position) / 2;
            } else if (prevList) {
                position = prevList.position + (prevList.position / 2);
            } else if (nextList) {
                position = nextList.position / 2;
            }

            // console.log(position);

            this.axios
                .put('/listings/' + list.id, {
                    position: position,
                    board_id: this.board.id,
                })
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });                
                    list.position = response.data.data.position;
                    // console.log('neue Position: ' + response.data.data.position);
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadUsers(){
            this.axios
                .get("/users")
                .then((response) => {
                    this.users = response.data.data;
                    const myself = this.users.findIndex(item => item.id == this.$auth.user().id);
                    this.$delete(this.users, myself);
                    // this.$parent.$refs.wrapper.classList.value += ' ' + this.board.cover.content
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadBoard(){
            this.axios
                .get("/boards/" + this.$route.params.id)
                .then((response) => {
                    this.board = response.data.data;
                    if(this.board.cover != null){
                        this.$refs.wrapper.classList.value += ' ' + this.board.cover.content
                    }
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
    },

    channel() {
        return 'private:board.' + this.$route.params.id;
    }, 

    // echo: {
    //     'BoardChangedEvent': (data) => {
    //         console.log(this);
    //         console.log(data.board);
    //         console.log(Vue.board);
    //         // this.$parent.board = data.board;
    //     },
    // },

    created() {
        this.loadBoard();
        this.loadTags();
        this.loadUsers();

        this.channel.listen('BoardChangedEvent', (payload) => {
            // console.log(payload);
            this.board = payload;
            this.$refs.wrapper.classList.value = 'content-wrapper kanban ' + this.board.cover.content
        });
    },


    beforeDestroy(){
        if(this.board.cover != null){
            // var cls = this.$refs.wrapper.classList.value.split(' ');
            // this.$delete(cls, cls.length-1);
            this.$refs.wrapper.classList.value = 'content-wrapper kanban';
        }
    }

}
</script>

<style scoped>
/* .ghost {
  opacity: 0.5;
  background: #c8ebfb;
} */
.new_board_list{
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    /* padding-inline-start: 40px; */
}

.new_board_item{
    width: 40px;
    height: 32px;
    display: list-item;
    text-align: -webkit-match-parent;
    color: #172b4d;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    border: none;
}

.new_board_item button {
    align-items: center;
    background-position: center center;
    background-size: cover;
    border-radius: 3px;
    box-shadow: none;
    color: rgba(255, 255, 255, 0.4);
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    min-height: 0;
    outline: 0;
    padding: 0;
    position: relative;
    line-height: 0;
    width: 100%;
    cursor: pointer;
    border: none;
}

.new_board_item_icon{
    position: relative;
    z-index: 1;
    flex-basis: 100%;
    font-size: 16px;
    /* height: 24px; */
    /* line-height: 24px; */
    display: inline-block;
}

.new_board_item_icon_self{
    color: rgb(255, 255, 255);
    display: inline-block;
    fill: inherit;
    flex-shrink: 0;
    line-height: 1;
}

.board-header-facepile {
    cursor: default;
    float: left;
    margin-left: 4px;
    overflow: hidden;
    padding: 2px 0 0 2px;
}

.member {
    background-color: #dfe1e6;
    border-radius: 25em;
    color: #172b4d;
    cursor: pointer;
    display: block;
    float: left;
    height: 32px;
    margin: 0 4px 4px 0;
    overflow: visible;
    position: relative;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    width: 32px;
    z-index: 0;
}

.board-header-facepile .member {
    float: left;
    height: 28px;
    margin: 0 0 0 -2px;
    width: 28px;
    z-index: 1;
}
.member-initials {
    display: block;
    font-size: 12px;
    font-weight: 700;
    height: 32px;
    left: 0;
    line-height: 32px;
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
}
.board-header-facepile .member .member-initials {
    height: 28px;
    line-height: 28px;
    width: 28px;
}
</style>